import React from "react"
import { graphql } from "gatsby"
import Layout from "../components/layout"
import SEO from "../utils/seo"
import richText from '../utils/richText'

const Taller = ({ data }) => {
  const {  titol, contingut } = data.contentfulPaginaEstatica
  const descripcio = richText(contingut.json)

  return (
    <Layout title={titol}> 
      <SEO title={titol} />
      <div className="container">
        <br /><br />
        <div className="descripcio">
          {descripcio}
        </div>
        
      </div>
    </Layout>
  )
}
export default Taller
export const pageQuery = graphql`
  query($slug: String!) {
    contentfulPaginaEstatica(slug: { eq: $slug }){
        id
        titol
        slug
        contingut {
        json
        }
    }
  }
`
